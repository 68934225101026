import React from 'react'
import { Container } from 'react-bootstrap'
import "./Insta.css"
const Insta = () => {
  return (
    <Container fluid id="Insta">
      <Container className='insta_container'>
        <div className='page_heading_div cat_heading mb-4'>
          <heading>Instagram</heading>
        </div>

        <div>
          <a href="https://instagram.com/aurora.light.studio?igshid=MzRlODBiNWFlZA==">
            <div className='imgs_div'>
              <img className="insta_img" src={require("../../assets/insta1.png")} />
              <img className="insta_img" src={require("../../assets/insta2.png")} />
            </div>
          </a>

          <div className='mt-4 goto_link_div'>
            <a className="goto_link" href="https://instagram.com/aurora.light.studio?igshid=MzRlODBiNWFlZA==">
              <p>Go to Profile </p>
            </a>
          </div>
        </div>
      </Container>
    </Container>
  )
}

export default Insta