const data = [
    {
        id: "1",
        question: "Who are the Aurora Lights?",
        answer: "Founded in 2019, Aurora Lights specializes in high-quality, innovative lighting solutions, including a unique range of hanging lights for diverse spaces.",
        image: "catlight.png"
    },
    {
        id: "2",
        question: "Do you have any custom lighting options?",
        answer: "We have a wide selection of hanging lights that can be customised based on size and shape to fit your specific needs. Our design team works with clients to choose the right dimensions "
    },
    {
        id: "3",
        question: "Do you offer shipping options? ",
        answer: "Yes, we have shipping choices for customers who need home delivery. You can contact our customer service by filling out a contact form on our website. Our specialised teams are here to help you with any shipping-related questions or problems."
    },
   

]
export default data;