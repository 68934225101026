import React from 'react'
import "./Category.css"
import { Container } from 'react-bootstrap'

const Category = () => {
    return (
        <Container fluid id="Category">
            <Container className='cat_inner_container'>
                <div className='page_heading_div cat_heading mb-5'>
                    <heading>our categories</heading>
                </div>

                <wrapper className="d-flex justify-content-between align-items-center">
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <img className='catt_img' src={require("../../assets/catlight.png")} />
                        <p className='img_text'>black series</p>
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <img className='catt_img' src={require("../../assets/catlight.png")} />
                        <p className='img_text'>pastel series </p>
                    </div>
                </wrapper>
                <wrapper className="d-flex justify-content-center align-items-center mt-5">
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <img className='catt_img' src={require("../../assets/catlight.png")} />
                        <p className='img_text'>gold series </p>
                    </div>
                </wrapper>
            </Container>
        </Container>
    )
}

export default Category