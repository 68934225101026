import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';

const ImageCarousel = () => {
  return (
    <div id="Home">
      <Carousel controls={false}>
        <Carousel.Item >
          <img
            className="d-block w-100 "
            src={require("../../assets/Frame20.png")}
            alt="First slide"
          />
          <Carousel.Caption className="left-center-caption">
            <div className='page_heading_div '>
              <p >Elegant <span className='heading_span'>Lighting</span></p>
              <p >for Your</p>
              <p > <span className='heading_span'>Space</span></p>
            </div>
            <div className='top_heading_text'>
              <p >Illuminate your space with a touch of class. Our hanging lights not only brighten your rooms but also add a dash of personality and charm.</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../../assets/frame21.png")}
            alt="First slide"
          />
          <Carousel.Caption className="left-center-caption">
            <div className='page_heading_div'>
              <p> <span className='heading_span'>Change</span> the Mood </p>
              <p> with Soft, Pastel </p>
              <p>Pendant <span className='heading_span'>Lights</span></p>
            </div>
            <div className='top_heading_text'>
              <p>Pastel hanging lights create a serene atmosphere in bedrooms, spa and yoga rooms, offering soft illumination for stress relief and better sleep.</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../../assets/frame22.png")}
            alt="First slide"
          />
          <Carousel.Caption className="left-center-caption">
            <div className='page_heading_div'>
              <p > <span className='heading_span'>Radiated</span> </p>
              <p>Brilliance With</p>
              <p> <span className='heading_span'>Each Gleam</span></p>
            </div>
            <div className='top_heading_text'>
              <p>Enjoy the spectacular Lights We've Created for Your Luxurious Home or Business. The walls and floor sparkle with a fluttering, glowing arrangement.</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default ImageCarousel;


