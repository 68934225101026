import React from 'react'
import "./Cards.css"

const Cards = (props) => {
  return (
    <div class="card_div">
  <img src={props.topimg} className="card-img-top" alt="..."/>
  <div class="card-body">
    <div className="title_btn_div">
    
    <div >
    <h5 class="card-title1">{props.title1}</h5>
    <h5 class="card-title2">{props.title2}</h5>
    </div>
    
    <div>
 <a href="https://www.amazon.in/s?i=merchant-items&me=A3RH6WK6BZKY5N&srs=27943762031&fs=true&ref=lp_27943762031_sar" class="card-btn">{props.btn}
 <img className="btn_img"src={props.img} alt=""/>
 </a>
 </div>
 
 </div>
  </div>
</div>
  )
}

export default Cards