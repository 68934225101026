import React, { useState, useEffect, useRef } from 'react';
import { IoPersonOutline } from 'react-icons/io5';
import { MdOutlineMailOutline } from 'react-icons/md';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import { Container } from 'react-bootstrap'
import ContactForm from '../ContactForm/ContactForm';
import "./Contact.css"

const Contact = () => {


  return (
    <Container fluid id="Contact" className="contactus_containerfluid">
<Container  >
<wrapper>
<div className='page_heading_div about_heading' >
    <heading >contact us  </heading>
</div>
<div className='contact_subheading_div'>
    <p>Thank you for showing interest in Aurora Lights. If you have any questions or would like additional information, feel free to contact us at any time.</p>
</div>
</wrapper>

<wrapper className="form_flex_contact">
    <div>
<ContactForm/>
</div>

<div className='black_box'>
<p>Info</p>
<a href="mailto:" className='contact_a_tag'>
<div className='d-flex justify-content-start align-items-center'>
 <MdOutlineMailOutline style={{marginTop:"-7px",marginRight:"15px"}}/>
<p style={{textTransform:"lowercase"}}>aurorals1001@gmail.com</p>
</div>
</a>

<a href="tel:9307950507" className='contact_a_tag'>
<div className='d-flex justify-content-start align-items-center'>
    <BsTelephone style={{marginTop:"-7px",marginRight:"15px"}}/>
<p>  +91 9307950507</p>
</div>
</a>

<div className='contact_icon_div'>
    <a href="https://www.facebook.com/profile.php?id=100090555109807&mibextid=ZbWKwL">
<img className="contact_icon"src={require("../../assets/facebookicon.png")} alt="icon"/>
</a>
<a  href="https://instagram.com/aurora.light.studio?igshid=MzRlODBiNWFlZA==">
<img className="contact_icon" src={require("../../assets/instaicon.png")} alt="icon"/>
</a>
<a href="https://wa.me/9307950507">
<img className="contact_icon" src={require("../../assets/whatsappiconn.png")} alt="icon"/>
</a>
<a href="https://www.amazon.in/s?i=merchant-items&me=A3RH6WK6BZKY5N&srs=27943762031&fs=true&ref=lp_27943762031_sar">
<img className="contact_icon" src={require("../../assets/amazonicon.png")} alt="icon"/>
</a>
</div>
</div>
</wrapper>

    </Container>
</Container>
  )
}

export default Contact