import React from 'react'
import { Container } from 'react-bootstrap'
import Accordion from '../Accordian/Accordian'
import ImgForm from "../ImgForm/ImgForm"
import "./About.css"

const About = () => {
    
  return (
    <>
   <Container fluid className='about_container_fluid_desktop' id="About">
    <Container className='about_container'>
    <wrapper className="about_heading_text_wrapper">
        <div className='heading_underline_div'>
    <div className='page_heading_div about_heading' >
    <heading >about & progress</heading>
</div>
    <div className='underline_div'>
        <img className='underline' src={require("../../assets/underline.png")} alt=""/>
    </div>
    </div>

<div className='sub_heading_div'>
    <p>Welcome to the Aurora Lights. Established in 2019, we’re committed to pushing boundaries in lighting design while prioritising customer satisfaction. What sets us apart is our focus on sustainable technologies and high-quality materials. </p>
</div>
 {/*  */}
 <div className='d-flex justify-content-start align-items-start mt-5'>
    <img className="light_img"src={require("../../assets/catlight.png")}alt=""/>
    <div className='text_div'>
    <div style={{fontWeight:"600"}}>
    <heading >Eco-Friendly Solutions</heading>
    </div>
    <p>We're at the forefront of sustainable lighting solutions, using eco-friendly materials and energy-efficient designs to minimise environmental impact.</p>
    </div>
</div>
{/*  */}
<div className='heading_underline_div'>
<div className='accordian_div'>
<div className='page_heading_div about_heading' >
    <heading >faqs</heading>
</div>
<div className='underline_div'>
        <img className='underline' src={require("../../assets/underline.png")} alt=""/>
    </div>
    </div>
    
    <div>
    <Accordion/>
</div>
</div>
</wrapper>
{/*  */}
<wrapper className="about_img_wrapper">
    <ImgForm/>
    {/* <img className="about_img"src={require("../../assets/Frame19.png")}alt=""/> */}
</wrapper>
    </Container>
    </Container>
{/* ------------------------------------------------------------------------ */}
    <Container fluid className='about_container_fluid_mobile'>
    <Container className='about_container'>
    <wrapper className="mobile_h_text">
    
    <div className='page_heading_div about_heading' >
    <heading >about & progress</heading>
    <div className='seller_underline_div'>
        <img className='seller_underline' src={require("../../assets/underline.png")} alt=""/>
    </div>
</div>

<div className='sub_heading_div'>
    <p>Welcome to the Aurora Lights. Established in 2019, we’re committed to pushing boundaries in lighting design while prioritising customer satisfaction. What sets us apart is our focus on sustainable technologies and high-quality materials. </p>
</div>

<div className='d-flex justify-content-start align-items-start'>
    <img className="light_img"src={require("../../assets/catlight.png")}alt=""/>
    <div className='text_div'>
        <div style={{fontWeight:"600"}}>
    <heading >Eco-Friendly Solutions</heading>
    </div>
    <p>We're at the forefront of sustainable lighting solutions, using eco-friendly materials and energy-efficient designs to minimise environmental impact.</p>
    </div>
</div>
</wrapper>

<wrapper className="about_img_wrapper">
    <ImgForm/>
 </wrapper>

<wrapper className='accordian_div'>

<div className='smFaq_underline_div mt-5'>
<div className='page_heading_div ' >
    <heading >faqs</heading>
</div>
<img className='sm_underline' src={require("../../assets/underline.png")} alt=""/>
</div>
  
     <Accordion/>
</wrapper>
    </Container>
    </Container>
    
    {/* <Container fluid className='about_container_fluid_mobile'>
<Container>
    <wrapper>
<div className='page_heading_div about_heading' >
    <heading >about & progress</heading>
</div>
<div className='sub_heading_div'>
    <p>Lorem Ipsum is a filler text that is used to fill the space between the elements of a web page or a document. Learn how to generate, customize and use Lorem Ipsum with this online tool </p>
</div>
</wrapper>

<wrapper className="d-flex justify-content-between align-items-center">
<div className='d-flex justify-content-center align-items-satrt'>
    <img className="light_img"src={require("../../assets/catlight.png")}alt=""/>
    <div className='text_div'>
    <heading>Lorem Ipsum </heading>
    <p>Lorem Ipsum is a filler text that is used to fill the space between the elements of a web page or a document. Learn how to generate.</p>
    </div>
</div>
<div className="about_img_wrapper d-flex justify-content-center">
    <img className="about_img"src={require("../../assets/Frame19.png")}alt=""/>
</div>
</wrapper>
</Container>

<Container className='d-flex justify-content-between align-items-center'>
<div className="about_img_wrapper d-flex justify-content-center align-items-center">
    <img className="about_img"src={require("../../assets/Frame19.png")}alt=""/>
</div>
<div className='accordian_outer_wrapper d-flex flex-column justify-content-center align-items-center'>
<div className='page_heading_div ' >
    <heading >faqs</heading>
</div>
    <Accordion/>
</div>
</Container>
    </Container>  */}

    </>
  )
}

export default About;