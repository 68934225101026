import React from 'react'
import { Container } from 'react-bootstrap'
import "./BestSeller.css"
import Cards from '../Cards/Cards'

const BestSeller = () => {
  return (
    <Container fluid id="BestSeller">
<Container>
<div className='page_heading_div cat_heading mb-5'>
    <heading>best seller</heading>
    <div className='seller_underline_div'>
        <img className='seller_underline' src={require("../../assets/underline.png")} alt=""/>
    </div>
</div>



<wrapper className="bs_cards_wrappers">
<div  style={{borderBottom:"5px solid #000"}} >
  <Cards topimg={require("../../assets/b1.jpg")} title1="curve box"  title2=" light" img="btn.png"  />
</div>

<div  style={{borderBottom:"5px solid #CC8819"}} >
  <Cards topimg={require("../../assets/b2.jpg")}  title1="pendant"  title2="light " img="btn.png" />
</div>

<div  style={{borderBottom:"5px solid #000"}} >
  <Cards topimg={require("../../assets/b3.jpg")}  title1="round pendant "  title2="light" img="btn.png" />
</div>

</wrapper>
</Container>
  </Container>
  )
}

export default BestSeller