import React from 'react';
import { Container } from 'react-bootstrap';
import rectangle from "../../assets/rectangle1.png";
import "./Video.css";

const Video = () => {
  return (
    <Container fluid className="video_containerFluid" style={{
      backgroundImage: `url(${rectangle})`,
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      border: "none",
      outline: "none"
    }}>
      <video className='video' controls autoPlay muted style={{ backgroundColor: 'black' }}>
        <source src={require("../../assets/video.mp4")} type="video/mp4" />
      </video>
    </Container>
  );
};

export default Video;
