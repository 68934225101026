import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SomeNew.css';

const SomeNew = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  };

  return (
    <Container fluid id="SomeNew">
      <div className='page_heading_div cat_heading mb-5'>
        <heading>something new</heading>
      </div>
      <Slider {...sliderSettings}>
        <Container className='d-flex justify-content-center align-items-center'>
          <wrapper className="all_content_wrapper">
            <wrapper className="flex_img_wrapper">
              <img className="img1" src={require("../../assets/Snew1.png")} alt="items" />
              <img className="img2" src={require("../../assets/Snew2.png")} alt="items" />
            </wrapper>
            <wrapper className="img_descp_wrapper">
              <div className='img_heading'>
                <heading>new catalogue</heading>
              </div>
              <div>
                <p className="para_heading" style={{ fontWeight: "600" }}>Black Lighting Fixtures</p>
                <text className='para_text'>Immerse yourself in the timeless allure of our black hanging lights. Crafted with precision, We offer captivating visuals and energy efficiency.</text>
              </div>
            </wrapper>
          </wrapper>
        </Container>

        <Container className='d-flex justify-content-center align-items-center'>
          <wrapper className="all_content_wrapper">
            <wrapper className="flex_img_wrapper">
              <img className="img1" src={require("../../assets/R2.png")} alt="items" />
              <img className="img2" src={require("../../assets/L33.png")} alt="items" />
            </wrapper>
            <wrapper className="img_descp_wrapper">
              <div className='img_heading'>
                <heading>new catalogue</heading>
              </div>
              <div>
                <p className="para_heading" style={{ fontWeight: "600" }}>Pastel Lighting Fixtures</p>
                <text className='para_text'>Experience the calming glow of our pastel lights. Ideal for peaceful living rooms and enhancing spa atmospheres, these lights offer soothing ambience.</text>
              </div>
            </wrapper>
          </wrapper>
        </Container>

        <Container className='d-flex justify-content-center align-items-center'>
          <wrapper className="all_content_wrapper">
            <wrapper className="flex_img_wrapper">
              <img className="img1" src={require("../../assets/R1.png")} alt="items" />
              <img className="img2" src={require("../../assets/L2.png")} alt="items" />
            </wrapper>
            <wrapper className="img_descp_wrapper">
              <div className='img_heading'>
                <heading>new catalogue</heading>
              </div>
              <div>
                <p className="para_heading" style={{ fontWeight: "600" }}>Gold Lighting Fixtures</p>
                <text className='para_text'>Discover our gold-hanging lights, a blend of opulence and sophistication. These durable, handcrafted pieces are timeless works of art that complement any space.</text>
              </div>
            </wrapper>
          </wrapper>
        </Container>
      </Slider>


    </Container>
  )
}

export default SomeNew