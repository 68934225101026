import React from 'react'
import { Container } from 'react-bootstrap'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./Client.css"

const client = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  };
  return (
   <Container fluid className='client_container_fluid'>
    <Slider {...sliderSettings} className='client_slider'>
   <Container className='client_content_container'>
<div className='flex_div1'>
    <img className="client_img"src={require("../../assets/c1.png")}alt=""/>
    <p>SAKSHI</p>
</div>

<div className='flex_div2'>
   <img className="comment_img"src={require("../../assets/v1.png")}alt=""/><br/>
    <p>The Aurora Light’s Gold Lighting Fixtures are stunning. They brought a feeling of classic beauty into my house. The standard of these appliances is superb.</p>
</div>
   </Container>

   <Container className='client_content_container'>
<div className='flex_div1'>
    <img className="client_img"src={require("../../assets/c2.png")}alt=""/>
    <p>shweta</p>
</div>

<div className='flex_div2'>
   <img className="comment_img"src={require("../../assets/v1.png")}alt=""/><br/>
    <p>Aurora Lights helped me choose the perfect lighting for my spa. My clients love the calming atmosphere!</p>
</div>
   </Container>

   <Container className='client_content_container'>
<div className='flex_div1'>
    <img className="client_img"src={require("../../assets/c3.png")}alt=""/>
    <p>aastha</p>
</div>

<div className='flex_div2'>
   <img className="comment_img"src={require("../../assets/v1.png")}alt=""/><br/>
    <p>The black pendant light from Aurora Lights is a conversation starter Truly a work of art. </p>
</div>
   </Container>
   </Slider>
   </Container>
  )
}

export default client