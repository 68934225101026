import React, { useEffect,useState } from "react"
import logo from './logo.svg';
import { BrowserRouter as Router, Route,  } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import  Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Pages/Home";
import Category from "./Components/Pages/Category";
import SomeNew from "./Components/Pages/SomeNew";
import About from "./Components/Pages/About";
import BestSeller from "./Components/Pages/BestSeller";
import SpclProduct from "./Components/Pages/SpclProduct"
import Insta from "./Components/Pages/Insta";
import Contact from "./Components/Pages/Contact";
import ContactForm from "./Components/ContactForm/ContactForm";
import Footer  from "./Components/Footer/Footer";
import Video from "./Components/Pages/Video";
import Client from "./Components/Pages/Client";
import LoadingSpinner from "./Components/LoadingSpinner/LoadingSpinner"

function App() {

  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
 setTimeout(() => {
       setIsLoading(false); 
     }, 2500); 
   }, []);

  return (
    <div className="App">
          {isLoading ? (
      <LoadingSpinner /> 
    ) : (
      <>
<Navbar/>
<Home/>
<Category/>
<SomeNew/>
<About/>
<BestSeller/>
<Video/>
<SpclProduct/>
<Client/>
<Insta/>
<Contact/>
<Footer/>
</>
  )}
{/* <ContactForm/> */}
</div>
  );
}

export default App;
