import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./SpclProduct.css"
import Cards from '../Cards/Cards'

const BestSeller = () => {
  return (
    <Container fluid  id="specialProducts"> 
<Container>
<div className='page_heading_div cat_heading mb-5'>
    <heading>special products</heading>
    <div className='seller_underline_div'>
        <img className='seller_underline' src={require("../../assets/underline.png")} alt=""/>
    </div>
</div>

<wrapper className="bs_cards_wrappers">
<div style={{borderBottom:"5px solid #000"}} >
  <Cards topimg={require("../../assets/s1.png")} title1="box curve   "  title2="light" img="btn.png"  />
</div>

<div style={{borderBottom:"5px solid #CC8819"}} >
  <Cards topimg={require("../../assets/s2.png")}  title1="round up-"  title2="down light" img="btn.png" />
</div>

<div style={{borderBottom:"5px solid #000"}} >
  <Cards topimg={require("../../assets/s3.png")}  title1="parametric "  title2="curve light"  img="btn.png"/>
</div>

</wrapper>
</Container>


<Container className='deco_cards_container'> 

    <div className='d-flex justify-content-around align-items-start deco_div'>
    <div className='deco_card_text_div'>
      <p className='deco_card_heading'>decorate <br/>lights</p>  
      <p className='deco_card_text'>Illuminate the festivities with our limited editions!</p>
      <a href="#Contact"> <p style={{color:"#000",marginTop:"35px",fontWeight:"200"}}>Contact Us</p></a>
    </div>
    <a href="#Contact" >
    <div className='p_img_div'>
        <img className="p_img"src={require("../../assets/p1.png")}alt=""/>
    </div>
    </a>
    </div>

  
    <div className='d-flex justify-content-around align-items-center deco_div'>
    <div className='deco_card_text_div'>
      <p className='deco_card_heading'>Big <br/>Saving</p>  
      <p className='deco_card_text'>Light up the festivities with our flashy discounts!</p>
      <a href="#Contact"><p style={{color:"#000",marginTop:"35px",fontWeight:"200",}}>Contact Us</p></a>
    </div>
    <a href="#Contact" >
    <div className='p_img2_div'>
 <img className="p_img" src={require("../../assets/deco.png")}alt=""/>
    </div>
    </a>
    </div>
</Container>
  
  </Container>
  )
}

export default BestSeller